import Swiper from "swiper/bundle";
import "swiper/css/bundle";

import Component from "gia/Component";

// Extend the GIA component
export default class Header extends Component {
  // setup
  constructor(element) {
    super(element);
    this.ref = {};
  }

  // mount is equivalent to $('document').ready and will only fire if your component is on the page
  // add event listeners here
  mount() {
    document.addEventListener("scroll", this.headerScroll.bind(this));
  }

  headerScroll() {
    let scrollpos = window.scrollY;

    const header = this.element;
    const scrollChange = 100;

    const add_class_on_scroll = () => header.classList.add("small");
    const remove_class_on_scroll = () => header.classList.remove("small");

    window.addEventListener("scroll", function () {
      scrollpos = window.scrollY;

      if (scrollpos >= scrollChange) {
        add_class_on_scroll();
      } else {
        remove_class_on_scroll();
      }
    });
  }
}
