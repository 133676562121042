// import GIA dependancy
import Component from "gia/Component";

// Extend the GIA component
export default class filter_projects extends Component {
  constructor(element) {
    super(element);
  }

  mount() {
    $("#ajax-filter").on("click", "input", function (e) {
      filter_blog();
    });

    //Separate event for clicking on the 'load-more' button (which should be added to the form with the class "load-more"
    $("body").on("click", "a#load-more", function (e) {
      filter_blog(true);
    });

    function filter_blog(next_page = false) {
      var page = $("#js-filter").data("page"); //get the current page
      var totalpages = $("#number_pages").val(); //get the total pages

      if (next_page) {
        page += 1;
      } else {
        page = 1;
      }
      $("#js-filter").data("page", page); //save back to the data attribute

      var blog_category = $('input[name="blog_category"]:checked').val();

      var ajax_data = {
        blog_category: blog_category,
        action: "filter_blog",
        // nonce: winc.nonce,
        posts_per_page: 2,
        paged: page,
      };

      $.ajax({
        url: winc.ajaxurl,
        data: ajax_data,
        type: "post",
        success: function (response) {


          if (next_page) {
            $("#js-filter").append(response);
          } else {
            $("#js-filter").html(response);
          }
          var totalpages = $("#number_pages").val();

          if (page >= totalpages) {
            $("a#load-more").hide();
          } else {
            $("a#load-more").show();
          }
        },

        error: function (response) {
          console.warn(response);
        },
      });
    }
  }
}
