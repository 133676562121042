// import GIA dependancy
import Component from "gia/Component";

// Extend the GIA component
export default class Modal extends Component {
  // setup
  constructor(element) {
    super(element);

    this.ref = {
      ModalOverlay: null,
      ModalInner: null,
      ModalClose: null,
    };
  }

  mount() {
    document.addEventListener("DOMContentLoaded", this.openModal.bind(this));
    this.ref.ModalClose.addEventListener("click", this.closeModal.bind(this));
  }

  openModal() {
    setTimeout(() => {
      if (sessionStorage.getItem("ModalSeen") === null) {
        this.element.classList.add("active");
      } else {
      }
    }, 20000);
  }

  closeModal() {
    sessionStorage.setItem("ModalSeen", "ModalSeen");
    this.element.classList.remove("active");
  }
}
