import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
import Component from "gia/Component";

// Extend the GIA component
export default class Inview extends Component {
  // setup
  constructor(element) {
    super(element);
    this.ref = {};
  }

  // mount is equivalent to $('document').ready and will only fire if your component is on the page
  // add event listeners here
  mount() {
    let sections = gsap.utils.toArray("[data-scroll]");

    sections.forEach((section) => {
      gsap.timeline({
        scrollTrigger: {
          trigger: section,
          start: "top center",
          end: "bottom top",
          scrub: true,
          toggleClass: "in-view",
          //  markers: true,
        },
      });
    });
  }
}
