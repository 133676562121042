import Swiper from "swiper/bundle";
import "swiper/css/bundle";

import Component from "gia/Component";

// Extend the GIA component
export default class Slider extends Component {
  // setup
  constructor(element) {
    super(element);
    this.ref = {};
  }

  // mount is equivalent to $('document').ready and will only fire if your component is on the page
  // add event listeners here
  mount() {
    const swiper = new Swiper(this.element, {
      pagination: {
        el: ".c-slider-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      speed: 1000,
      //direction: "vertical",
      effect: "fade",
      parallax: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      loop: true,
      slidesPerView: 1,
    });
    //
  }
}
