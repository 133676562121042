import Component from "gia/Component";
import { Loader } from "@googlemaps/js-api-loader";

export default class Map extends Component {
  constructor(element) {
    super(element);

    this.loader = new Loader({
      apiKey: "AIzaSyCVQaICY9YyY4BoO4aSf8adPZl8d5BqMAY",
      version: "weekly",
    });

    // this.map = null;
    this.ref = {
      map: null, // looks for single element
    };
    // this.initMap(this.element);
  }

  /** initMap */
  async initMap($el) {
    const $markers = $el.querySelectorAll(".marker");

    const styles = [
      {
        stylers: [
          {
            hue: "#efeeea",
          }, // This is the base colour for everything
          {
            saturation: -100,
          }, // This takes all the colour out
          {
            lightness: 0,
          }, // This dulls the map down to not be seen
          {
            gamma: 1.38,
          }, // This is the contrast (Gamma)
        ],
      },
      {
        // Remove all complex lines from roads
        featureType: "road",
        elementType: "geometry",
        stylers: [
          {
            lightness: 1,
          }, // Make the roads darkest
          {
            visibility: "simplified",
          }, // Simplify the roads
        ],
      },
      {
        // Remove street and road names
        featureType: "road",
        elementType: "labels",
        stylers: [
          {
            // visibility: "off"
          }, // No longer visible
        ],
      },
      {
        // Remove all the points of interests off the map
        featureType: "poi",
        elementType: "labels",
        stylers: [
          {
            visibility: "off",
          }, // No longer visible
        ],
      },
    ];

    const mapOptions = {
      zoom: parseInt($el.dataset.zoom) || 16,
      mapTypeId: "roadmap",
      styles: styles, // Get the styles defined at the top into here
      scrollwheel: false,
      disableDefaultUI: true,
    };

    await this.loader.load();

    this.map = new google.maps.Map($el, mapOptions);

    this.map.markers = [];

    $markers.forEach(($marker) => {
      this.initMarker($marker, this.map);
    });

    this.centerMap(this.map);
  }

  /** initMarker */
  initMarker($marker, map) {
    const lat = parseFloat($marker.dataset.lat);
    const lng = parseFloat($marker.dataset.lng);
    const latLng = { lat, lng };

    const point = {
      path: "M39 20a19 19 0 1 1-38 0 19 19 0 1 1 38 0z",
      fillColor: "#b0a891",
      fillOpacity: 1,
      scale: 0.8,
      strokeWeight: 0,
    };

    const marker = new google.maps.Marker({
      position: latLng,
      map: map,
      icon: point,
    });

    map.markers.push(marker);

    if ($marker.innerHTML) {
      const infowindow = new google.maps.InfoWindow({
        content: $marker.innerHTML,
      });

      google.maps.event.addListener(marker, "click", function () {
        infowindow.open(map, marker);
      });
    }
  }

  /** centerMap */
  centerMap(map) {
    const bounds = new google.maps.LatLngBounds();
    map.markers.forEach((marker) => {
      bounds.extend({
        lat: marker.position.lat(),
        lng: marker.position.lng(),
      });
    });

    if (map.markers.length === 1) {
      map.setCenter(bounds.getCenter());
    } else {
      map.fitBounds(bounds);
    }
  }

  mount() {
    //
    this.initMap(this.ref.map);
  }
}
