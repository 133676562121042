// import GIA dependancy
import Component from "gia/Component";

// Extend the GIA component
export default class Accordion extends Component {
  constructor(element) {
    super(element);
  }

  mount() {
    $(".c-accordion .c-accordion__title").click(function () {
      $(this).parent().siblings().removeClass("active");

      $(this).parent().addClass("active");
      $(this).siblings().slideToggle();
      return false;
    });
  }
}
