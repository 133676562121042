import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Component from "gia/Component";

gsap.registerPlugin(ScrollTrigger); // import GIA dependancy

// Extend the GIA component
export default class Image extends Component {
  // setup
  constructor(element) {
    super(element);

    this.ref = {
      ImageSection: null,
    };

    this.image = this.element.querySelector("img");
    this.heightOffset = this.image.offsetHeight - this.ref.ImageSection.offsetHeight;
  }

  mount() {
    this.animate();
  }

  animate() {
    gsap.fromTo(
      this.image,
      {
        y: -this.heightOffset,
      },
      {
        scrollTrigger: {
          trigger: this.ref.ImageSection,
          start: "clamp(top bottom)",
          end: "clamp(bottom top)",
          scrub: true,
          // markers: true
        },
        y: this.heightOffset,
        ease: "none",
      }
    );
  }
}
