/**
 *  Example component using GIA syntax
 * 	See https://github.com/giantcz/gia
 *  and the article on CSS Tricks https://css-tricks.com/a-minimal-javascript-setup/#article-header-id-6
 *  Any questions please ask Duncan
 */

// import GIA dependancy
import Component from "gia/Component";

// Extend the GIA component
export default class QuickLinks extends Component {
  // setup
  constructor(element) {
    super(element);
    this.ref = {
      QuickLinksList: null,
    };

    let vw = document.documentElement.clientWidth;
  }

  // mount is equivalent to $('document').ready and will only fire if your component is on the page
  // add event listeners here

  mount() {
    function setSize() {
      if (this.ref.QuickLinksList.clientWidth < window.innerWidth) {
        this.element.classList.toggle("center");
        this.ref.QuickLinksList.classList.toggle("center");
        console.log("links wider than viewport");
      } else {
        // this.element.classList.remove("center");
        // this.ref.QuickLinksList.classList.remove("center");
        console.log("links narrower than viewport");
      }
    }
    // this.element.innerHTML = window.innerWidth + "<br>" + window.innerHeight;
    // window.addEventListener("resize", this.setSize.bind(this));
  }
}
