import Component from "gia/Component";

// Extend the GIA component
export default class Instagram extends Component {
  // setup
  constructor(element) {
    super(element);
    this.ref = {
      feed: null
    };
  }

  // Method to get the root domain of the current website
  getRootDomain() {
    var hostname = window.location.hostname;

    // Define your production and staging domain names
    var productionDomain = "breedonprioryspa.co.uk";
    var stagingDomain = "staging3.breedonprioryspa.co.uk";

    // Check if the hostname matches the production or staging domain
    if (hostname.includes(productionDomain)) {
      return productionDomain;
    } else if (hostname.includes(stagingDomain)) {
      return stagingDomain;
    } else {
      // Fallback to a default domain or handle it according to your requirements
      return "breedonprioryspa.co.uk";
    }
  }

  // mount is equivalent to $('document').ready and will only fire if your component is on the page
  // add event listeners here
  mount() {
    const script = document.createElement("script");

    // Get the root domain
    var rootDomain = this.getRootDomain();

    // Construct the URL with the root domain
    var scriptUrl = "https://" + rootDomain + "/wp-content/plugins/instagram-feed/js/sbi-scripts.min.js";


    // Set the source of the external script
    script.src = scriptUrl;

    // Append the script element to the head of the document
    document.head.appendChild(script);

    // Optionally, you can add a callback function to execute after the script has loaded
    script.onload = function () {
      console.log("External script loaded successfully");
    };

    // Optionally, you can handle errors if the script fails to load
    script.onerror = function () {
      console.error("Failed to load external script");
    };
  }
}
